import * as React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

export default function Acknowledgements() {
  const title = 'Acknowledgements';
  return (
    <Layout>

      <SEO title={title} />
      <h1>{title}</h1>
      <p><strong>Protect image</strong>: <a href='https://commons.wikimedia.org/wiki/File:Oslo_Airport_terminal_night_view.jpg' rel='noreferrer' target='_blank'>Oslo Airport at Dusk</a>, by Avinor Oslo lufthavn/Espen Solli</p>
      <p><strong>Prepare image</strong>: <a href='https://commons.wikimedia.org/wiki/File:1994%EB%85%84_12%EC%9B%94_7%EC%9D%BC_%EC%95%84%ED%98%84%EB%8F%99_%EB%8F%84%EC%8B%9C%EA%B0%80%EC%8A%A4_%ED%8F%AD%EB%B0%9C_%EC%82%AC%EA%B3%A01.JPG' rel='noreferrer' target='_blank'>Ahyeon-dong city gas explosion on December 7, 1994</a>, by Seoul Metropolitan Fire & Disaster Headquarters</p>
      <p><strong>Respond image</strong>: Creator: Speich Frederic, Credit: ZUMA24.com</p>
    </Layout>
  );
}
